require('./jquery.tablesorter.min.js');

require('./jquery.tablesorter.widgets.js');

require('./bootstrap');

require('jquery-ui/ui/widgets/datepicker.js');

/**
 * Take a javascript Date object and converts it m / d / Y
 * @param {Date} date 
 * @returns 
 */
function getFormattedDate(date) {
    var year = date.getFullYear();

    var month = (1 + date.getMonth()).toString();
    month = month.length > 1 ? month : '0' + month;

    var day = date.getDate().toString();
    day = day.length > 1 ? day : '0' + day;

    return month + '/' + day + '/' + year;
}

/**
 * This populates the catagory drop down when requesting to add a new catagory
 */
$(function() {

      $( ".clickable-row" ).on( "click", function() {
        window.location = $(this).data("href");
      });




    $(".tablesorter").tablesorter({
        theme : "bootstrap",
    
        widthFixed: true,

        dateFormat : "mmddyyyy", // set the default date format
    
        // widget code contained in the jquery.tablesorter.widgets.js file
        // use the zebra stripe widget if you plan on hiding any rows (filter widget)
        // the uitheme widget is NOT REQUIRED!
        widgets : ["columns", "zebra" ],
    
        widgetOptions : {
          // using the default zebra striping class name, so it actually isn't included in the theme variable above
          // this is ONLY needed for bootstrap theming if you are using the filter widget, because rows are hidden
          zebra : ["even", "odd"],
    
          // class names added to columns when sorted
          columns: [ "primary", "secondary", "tertiary" ],
    
          // reset filters button
          filter_reset : ".reset",
    
          // extra css class name (string or array) added to the filter element (input or select)
          filter_cssFilter: [

          ]
    
        }
      });
    























    $('.datepicker').datepicker();

    $('#addcat .level1 select').on('change', function () {

        $(".category-selected").text($("#addcat .level1 select option:selected").text());

        var categoryType = $("input[name=categoryType]").val();

        $.ajax({
            url : '/get-categories',
            type : 'post',
            data : {
                'type' : categoryType,
                'id': $(this).val(),
                "_token": $("input[name=_token]").val()
            },
            dataType:'json',
                success : function(response) {              
                    var len = response.length;

                    $(".level2").fadeIn();
                    $(".level2 select").empty();
                    $(".level2 select").append("<option value='0'>Add a level 2 term</option>");
                    for( var i = 0; i < len; i++){
                        var id = response[i]['id'];
                        var name = response[i]['name'];
                        
                        $(".level2 select").append("<option value='" + id + "'>" + name + "</option>");
    
                    }
            }
        });
    });

    $('#addcat .level2 select').on('change', function () {
        $(".category-selected").text($("#addcat .level2 select option:selected").text());
        $(".level3").fadeIn();
        $(".level3 select").empty();
        $(".level3 select").append("<option value='0'>Add a level 3 term </option>");
    });

    $('#addsynonym .level1 select').on('change', function () {

       $(".category-selected").text($("#addsynonym .level1 select option:selected").text());

       var categoryType = $("input[name=categoryType]").val();


        $.ajax({
            url : '/get-categories',
            type : 'post',
            data : {
                'type' : categoryType,
                'id': $(this).val(),
                "_token": $("input[name=_token]").val()
            },
            dataType:'json',
                success : function(response) {              
                    var len = response.length;

                    $(".level2").fadeIn();
                    $(".level2 select").empty();
                    $(".level2 select").append("<option value='0'>Level 2 Categories</option>");
                    for( var i = 0; i < len; i++){
                        var id = response[i]['id'];
                        var name = response[i]['name'];
                        
                        $(".level2 select").append("<option value='" + id + "'>" + name + "</option>");
    
                    }
            }
        });
    });

    $('#addsynonym .level2 select').on('change', function () {

        $(".category-selected").text($("#addsynonym .level2 select option:selected").text());

        var categoryType = $("input[name=categoryType]").val();


        $.ajax({
            url : '/get-categories',
            type : 'post',
            data : {
                'type' : categoryType,
                'id': $(this).val(),
                "_token": $("input[name=_token]").val()
            },
            dataType:'json',
                success : function(response) {              
                    var len = response.length;

                    $(".level3").fadeIn();
                    $(".level3 select").empty();
                    $(".level3 select").append("<option value='0'>Level 3 Categories</option>");
                    for( var i = 0; i < len; i++){
                        var id = response[i]['id'];
                        var name = response[i]['name'];
                        
                        $(".level3 select").append("<option value='" + id + "'>" + name + "</option>");
    
                    }
            }
        });
    });

    $('#addsynonym .level3 select').on('change', function () {

        $(".category-selected").text($("#addsynonym .level3 select option:selected").text());

      
    });

    /**
     * Set the values if make live now is checked
     */
    $(document).on('change', '#makeLive', function() {
        if(this.checked) {
            var date = new Date();
            var formattedDate = getFormattedDate(date);
            $('.datepicker').val(formattedDate);
            $("#requestStatus").val("approved");
        }
    });

    /**
     * Conditionally hides/shws the goLive options based on decision
     */
    $('#requestStatus').on('change', function () {
        if( $( "#requestStatus option:selected" ).text() == "Accepted") {
            $( ".go-live-group > div" ).removeClass("hidden");
        } else if ( $( "#requestStatus option:selected" ).text() == "Rejected") {
            $( ".go-live-group > div" ).addClass("hidden");
        }
    });






});


